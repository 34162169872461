import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EventInputPage from './EventInputPage';
import ApprovalPage from './ApprovalPage';
import EventDetail from './pages/EventDetail';
import Home from './pages/Home';
import EventMap from './pages/EventMap';
import SubmittedList from './SubmittedList';
import EventUpload from './EventUpload';

const App = () => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google && window.google.maps) {
        setGoogleMapsLoaded(true);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  if (!googleMapsLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/eventinput" element={<EventInputPage />} />
        <Route path="/approval" element={<ApprovalPage />} />
        <Route path="/submittedlist" element={<SubmittedList />} />
        <Route path="/event/:id" element={<EventDetail />} />
        <Route path="/map/:id" element={<EventMap />} />
        <Route path="/event-upload" element={<EventUpload />} />
      </Routes>
    </Router>
  );
};

export default App;
