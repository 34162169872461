import React, { useState, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import GoogleMapReact from 'google-map-react';
import { Typography, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import mapStyles from './mapStyles';

const OverlayContent = ({ place, visible, setVisible, onMarkerClick }) => {
  const toggleDetail = (e) => {
    e.stopPropagation();
    setVisible(!visible);
  };

  const handleMarkerClick = (e) => {
    e.stopPropagation();
    if (onMarkerClick) {
      onMarkerClick(place.id);
    }
    toggleDetail(e);
  };

  const handleDetailClick = (e) => {
    e.stopPropagation();
    window.open(`/event/${place.id}`, '_blank');
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          cursor: 'pointer',
        }}
        onClick={handleMarkerClick}
      >
        <LocationOnIcon color="primary" fontSize="large" />
      </div>
      {visible && (
        <div
          style={{
            position: 'absolute',
            bottom: 'calc(100% + 8px)',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '220px',
            background: 'white',
            padding: '10px',
            border: '1px solid #333',
            borderRadius: '4px',
            fontSize: '12px',
            zIndex: 1000,
            cursor: 'pointer',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
          onClick={handleDetailClick}
        >
          <div style={{ position: 'relative' }}>
            <Typography variant="h6" style={{ fontSize: '16px', margin: 0 }}>
              {place.event_name}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setVisible(false);
              }}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Typography variant="body2" style={{ margin: '4px 0' }}>
            {moment(place.event_date).format('DD-MMM-YY')}
            {place.event_end_date
              ? ` ถึง ${moment(place.event_end_date).format('DD-MMM-YY')}`
              : ''}
          </Typography>
          <Typography variant="body2" style={{ marginBottom: '4px' }}>
            {place.event_location}
          </Typography>
          <img
            src={place.event_image_link || 'https://via.placeholder.com/150'}
            alt={place.event_name}
            style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
          />
        </div>
      )}
    </div>
  );
};

class CustomOverlay extends window.google.maps.OverlayView {
  constructor(position, place, map, initialVisible = false, onMarkerClick) {
    super();
    this.position = position;
    this.place = place;
    this.map = map;
    this.visible = initialVisible;
    this.onMarkerClick = onMarkerClick;
    this.div = null;
    this.root = null;
    this.setMap(map);
  }

  renderOverlay() {
    if (!this.div) return;
    if (!this.root) {
      this.root = createRoot(this.div);
    }
    this.root.render(
      <OverlayContent
        place={this.place}
        visible={this.visible}
        setVisible={(val) => {
          this.visible = val;
          this.renderOverlay();
        }}
        onMarkerClick={this.onMarkerClick}
      />
    );
  }

  onAdd() {
    if (!this.div) {
      this.div = document.createElement('div');
      this.div.style.position = 'absolute';
    }
    this.renderOverlay();
    const pane = this.getPanes().overlayMouseTarget;
    if (pane) {
      pane.appendChild(this.div);
    }
  }

  draw() {
    const projection = this.getProjection();
    if (!projection || !this.div) return;
    const pos = projection.fromLatLngToDivPixel(
      new window.google.maps.LatLng(this.position.lat, this.position.lng)
    );
    this.div.style.left = pos.x - this.div.offsetWidth / 2 + 'px';
    this.div.style.top = pos.y - this.div.offsetHeight + 'px';
  }

  onRemove() {
    if (this.div) {
      // Defer unmount to avoid synchronous unmount during React render
      setTimeout(() => {
        if (this.root) {
          this.root.unmount();
          this.root = null;
        }
      }, 0);

      if (this.div.parentNode) {
        this.div.parentNode.removeChild(this.div);
      }
      this.div = null;
    }
  }

  setVisible(newVisible) {
    this.visible = newVisible;
    this.renderOverlay();
  }
}

const Map = ({
  coordinates,
  places,
  setCoordinates,
  setBounds,
  selectedEventId,
  onMarkerClick,
}) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const overlaysRef = useRef([]);

  // Validate and log places data for debugging
  useEffect(() => {
    console.log('Map received places:', places);
    if (places) {
      places.forEach((place, index) => {
        if (!place.latitude || !place.longitude) {
          console.warn(`Place at index ${index} is missing latitude or longitude:`, place);
        }
      });
    }
  }, [places]);

  // Create new overlays when places or selectedEventId changes
  useEffect(() => {
    if (mapInstance && mapApi && places) {
      // Remove previous overlays
      overlaysRef.current.forEach((overlay) => overlay.setMap(null));
      overlaysRef.current = [];

      // Create new overlays for valid places
      places.forEach((place) => {
        if (
          place.latitude &&
          place.longitude &&
          typeof place.latitude === 'number' &&
          typeof place.longitude === 'number'
        ) {
          const pos = { lat: place.latitude, lng: place.longitude };
          const initialVisible = place.id === selectedEventId;
          const overlay = new CustomOverlay(
            pos,
            place,
            mapInstance,
            initialVisible,
            onMarkerClick
          );
          overlaysRef.current.push(overlay);
        } else {
          console.warn('Skipping place due to invalid coordinates:', place);
        }
      });
    }

    // Cleanup on unmount or when dependencies change
    return () => {
      overlaysRef.current.forEach((overlay) => overlay.setMap(null));
      overlaysRef.current = [];
    };
  }, [places, mapInstance, mapApi, selectedEventId, onMarkerClick]);

  // Update overlay visibility when selectedEventId changes
  useEffect(() => {
    overlaysRef.current.forEach((overlay) => {
      overlay.setVisible(overlay.place.id === selectedEventId);
    });
  }, [selectedEventId]);

  // Center the map when coordinates change
  useEffect(() => {
    if (mapInstance && coordinates) {
      mapInstance.panTo(new window.google.maps.LatLng(coordinates.lat, coordinates.lng));
    }
  }, [coordinates, mapInstance]);

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        center={coordinates}
        zoom={14}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: true,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMapInstance(map);
          setMapApi(maps);
          map.addListener('click', () => {
            overlaysRef.current.forEach((overlay) => overlay.setVisible(false));
          });
        }}
        onChange={({ center, bounds }) => {
          setCoordinates(center);
          setBounds({
            ne: { lat: bounds.ne.lat, lng: bounds.ne.lng },
            sw: { lat: bounds.sw.lat, lng: bounds.sw.lng },
          });
        }}
      >
        {/* Overlays are rendered via CustomOverlay */}
      </GoogleMapReact>
    </div>
  );
};

export default Map;