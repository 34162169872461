import React, { useState, useEffect, createRef } from 'react';
import { CircularProgress, Grid, Box } from '@mui/material';
import PlaceDetails from '../PlaceDetails/PlaceDetails';
import { styled } from '@mui/system';

const StyledContainer = styled(Box)({
  padding: '25px',
});

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '600px',
});

const ScrollableGrid = styled(Grid)({
  maxHeight: '65vh',
  overflowY: 'auto',
  paddingRight: '10px',
  paddingBottom: '10px',
});

const List = ({ places, childClicked, isLoading, onLocationClick }) => {
  const [elRefs, setElRefs] = useState([]);

  useEffect(() => {
    setElRefs((refs) => Array(places.length).fill().map((_, i) => refs[i] || createRef()));
  }, [places]);

  return (
    <StyledContainer>
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress size={50} />
        </LoadingContainer>
      ) : (
        <ScrollableGrid container spacing={3}>
          {places?.map((place, i) => (
            <Grid
              ref={elRefs[i]}
              key={place.id}
              item
              xs={12}
              onClick={() => onLocationClick(place)}
              style={{ cursor: 'pointer' }}
            >
              <PlaceDetails
                selected={Number(childClicked) === i}
                refProp={elRefs[i]}
                place={place}
              />
            </Grid>
          ))}
        </ScrollableGrid>
      )}
    </StyledContainer>
  );
};

export default List;
