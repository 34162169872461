import React from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Divider,
  useTheme
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import 'moment/locale/th';

const ClickableTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const ClickableButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: 'bold',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const labels = {
  TH: {
    eventDate: 'เริ่มต้น',
    endDate: 'สิ้นสุด',
    website: 'เว็บไซต์'
  },
  EN: {
    eventDate: 'Start Date',
    endDate: 'End Date',
    website: 'Website'
  }
};

const PlaceDetails = ({ place, selected, refProp, onLocationClick, language = 'TH' }) => {
  const theme = useTheme();

  if (selected) refProp?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const handleEventNameClick = () => {
    window.open(`/event/${place.id}`, '_blank');
  };

  moment.locale(language === 'TH' ? 'th' : 'en');

  const displayDate = (date) => moment(date).format('DD-MMM-YY');

  return (
    <Card elevation={6} sx={{ maxWidth: 345, m: 2, borderRadius: 3, boxShadow: 3 }}>
      <CardMedia
        component="img"
        height="180"
        image={
          place.event_image_link
            ? place.event_image_link
            : 'https://images.pexels.com/photos/3184183/pexels-photo-3184183.jpeg'
        }
        alt={place.event_name}
        sx={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
      />
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ClickableTypography
            gutterBottom
            variant="h5"
            onClick={handleEventNameClick}
            sx={{ fontWeight: 700, color: theme.palette.text.primary }}
          >
            {place.event_name}
          </ClickableTypography>
          {/* Removed the close IconButton */}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ my: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1.5 }}>
            <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 500 }}>
              {labels[language].eventDate}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
              {displayDate(place.event_date)}
            </Typography>
          </Box>
          {place.event_end_date && (
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1.5 }}>
              <Typography variant="subtitle1" color="text.secondary" sx={{ fontWeight: 500 }}>
                {labels[language].endDate}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                {displayDate(place.event_end_date)}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2, lineHeight: 1.6 }}>
          {place.event_summary}
        </Typography>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {place.website_link && (
            <ClickableButton size="small" onClick={() => window.open(place.website_link, '_blank')}>
              {labels[language].website}
            </ClickableButton>
          )}
        </CardActions>
        {/* New clickable event location at the bottom */}
        <Box
          sx={{
            mt: 2,
            textAlign: 'right',
            cursor: 'pointer',
          }}
          onClick={() => onLocationClick(place)}
        >
          <Typography variant="subtitle1" color="primary">
            {place.event_location}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlaceDetails;
