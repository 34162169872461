import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Paper
} from '@mui/material';
import axios from 'axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

const EventInputPage = () => {
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(null);
  const [eventLocation, setEventLocation] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventSummary, setEventSummary] = useState('');
  const [eventImageLink, setEventImageLink] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [language, setLanguage] = useState('TH'); // Default language to 'TH'
  const [type, setType] = useState('Concerts'); // Default type to 'Concerts'
  const [submitStatus, setSubmitStatus] = useState('');
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState('Kwang'); // Default user name

  const titles = {
    TH: {
      submitEvent: 'ส่งกิจกรรม',
      eventName: 'ชื่อกิจกรรม',
      eventDate: 'วันที่จัดกิจกรรม',
      eventEndDate: 'วันที่สิ้นสุดกิจกรรม',
      eventLocation: 'สถานที่จัดกิจกรรม',
      latitude: 'ละติจูด',
      longitude: 'ลองจิจูด',
      eventDescription: 'คำอธิบายกิจกรรม',
      eventSummary: 'สรุปกิจกรรม',
      websiteLink: 'ลิงค์เว็บไซต์',
      eventImageLink: 'ลิงค์รูปภาพกิจกรรม',
      language: 'ภาษา',
      type: 'ประเภท',
      submit: 'ส่งกิจกรรม',
      userName: 'ชื่อผู้ใช้'
    },
    EN: {
      submitEvent: 'Submit an Event',
      eventName: 'Event Name',
      eventDate: 'Event Date',
      eventEndDate: 'Event End Date',
      eventLocation: 'Event Location',
      latitude: 'Latitude',
      longitude: 'Longitude',
      eventDescription: 'Event Description',
      eventSummary: 'Event Summary',
      websiteLink: 'Website Link',
      eventImageLink: 'Event Image Link',
      language: 'Language',
      type: 'Type',
      submit: 'Submit Event',
      userName: 'User Name'
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate numeric inputs
    if (!latitude || isNaN(latitude)) {
      setSubmitStatus('Latitude must be a number.');
      setOpen(true);
      return;
    }
    if (!longitude || isNaN(longitude)) {
      setSubmitStatus('Longitude must be a number.');
      setOpen(true);
      return;
    }
    if (!websiteLink) {
      setSubmitStatus('Website link is required.');
      setOpen(true);
      return;
    }

    const eventData = {
      eventName,
      eventDate: moment(eventDate).utc().format(), // Convert to UTC ISO string
      eventEndDate: eventEndDate ? moment(eventEndDate).utc().format() : null,
      eventLocation,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      eventDescription,
      eventSummary,
      eventImageLink,
      websiteLink,
      language,
      type,
      userName,
    };

    try {
      // Submit the event data
      const postResponse = await axios.post(`${apiUrl}/events`, eventData);
      let verified = false;

      // Option 1: Check if the POST response returns the new event with an 'id'
      if (postResponse.data && postResponse.data.id) {
        verified = true;
      } else {
        // Option 2: Perform a GET request to verify the event was added.
        const getResponse = await axios.get(`${apiUrl}/events`);
        // Attempt to find the event by name (adjust criteria if needed)
        const createdEvent = getResponse.data.find(ev => ev.eventName === eventData.eventName);
        if (createdEvent) {
          verified = true;
        }
      }

      if (verified) {
        setSubmitStatus('Event submitted and verified successfully!');
      } else {
        setSubmitStatus('Event submitted, but verification failed. Please check manually.');
      }
      setOpen(true);

      // Clear the form fields
      setEventName('');
      setEventDate(new Date());
      setEventEndDate(null);
      setEventLocation('');
      setLatitude('');
      setLongitude('');
      setEventDescription('');
      setEventSummary('');
      setEventImageLink('');
      setWebsiteLink('');
      setLanguage('TH'); // Reset to default language
      setType('Concerts'); // Reset to default type
      setUserName('Kwang'); // Reset to default user name

    } catch (error) {
      console.error('Error submitting event:', error);
      setSubmitStatus('Error submitting event. Please try again.');
      setOpen(true);
    }
  };

  const eventTypes = [
    { value: "Product & Service Fair", label: { EN: "Product & Service Fair", TH: "งานแสดงสินค้าและบริการ" } },
    { value: "Promotions", label: { EN: "Promotions", TH: "โปรโมชั่นและส่วนลด" } },
    { value: "Arts & Films", label: { EN: "Arts & Films", TH: "ศิลปะและภาพยนตร์" } },
    { value: "Live Performances", label: { EN: "Live Performances", TH: "คอนเสิร์ตและการแสดงสด" } },
    { value: "Sports & Competitions", label: { EN: "Sports & Competitions", TH: "กีฬาและการแข่งขัน" } },
    { value: "Travel & Photos", label: { EN: "Travel & Photos", TH: "ท่องเที่ยวและภาพถ่าย" } },
    { value: "Business & Investment", label: { EN: "Business & Investment", TH: "ธุรกิจและการลงทุน" } },
    { value: "Education & Family", label: { EN: "Education & Family", TH: "การศึกษาและครอบครัว" } },
    { value: "Other", label: { EN: "Other", TH: "อื่นๆ" } },
  ];

  const currentTitles = titles[language];

  return (
    <Container component={Paper} style={{ maxHeight: '100vh', overflowY: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        {currentTitles.submitEvent}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>{currentTitles.language}</InputLabel>
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <MenuItem value="TH">Thai</MenuItem>
              <MenuItem value="EN">English</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel>{currentTitles.type}</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {eventTypes.map((eventType, index) => (
                <MenuItem key={index} value={eventType.value}>
                  {eventType.label[language]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={currentTitles.eventName}
              fullWidth
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={currentTitles.eventDate}
                value={eventDate}
                onChange={(newValue) => setEventDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={currentTitles.eventLocation}
              fullWidth
              value={eventLocation}
              onChange={(e) => setEventLocation(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={currentTitles.eventEndDate}
                value={eventEndDate}
                onChange={(newValue) => setEventEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={currentTitles.latitude}
              fullWidth
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={currentTitles.longitude}
              fullWidth
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={currentTitles.eventSummary}
              fullWidth
              multiline
              rows={2}
              value={eventSummary}
              onChange={(e) => setEventSummary(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={currentTitles.eventDescription}
              fullWidth
              multiline
              rows={4}
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={currentTitles.websiteLink}
              fullWidth
              value={websiteLink}
              onChange={(e) => setWebsiteLink(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={currentTitles.eventImageLink}
              fullWidth
              value={eventImageLink}
              onChange={(e) => setEventImageLink(e.target.value)}
            />
            {eventImageLink && (
              <Box mt={2}>
                <img src={eventImageLink} alt="Event" style={{ maxWidth: '100%', maxHeight: '300px' }} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{currentTitles.userName}</FormLabel>
              <RadioGroup
                row
                aria-label="userName"
                name="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              >
                <FormControlLabel value="Kwang" control={<Radio />} label="Kwang" />
                <FormControlLabel value="Kag" control={<Radio />} label="Kag" />
                <FormControlLabel value="Other" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {currentTitles.submit}
            </Button>
          </Grid>
        </Grid>
      </form>
      {submitStatus && (
        <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
          <Alert onClose={() => setOpen(false)} severity={submitStatus.includes('success') ? 'success' : 'error'}>
            {submitStatus}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default EventInputPage;
