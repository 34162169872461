import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment-timezone';

const apiUrl = process.env.REACT_APP_API_URL ;
const translateApiUrl = 'https://translation.googleapis.com/language/translate/v2'; // Google Translate API endpoint

const ApprovalPage = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [translatedEvent, setTranslatedEvent] = useState(null);
  const [comments, setComments] = useState('');
  const [approvalStatus, setApprovalStatus] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  // Fetch pending events on component mount
  useEffect(() => {
    const fetchPendingEvents = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await axios.get(`${apiUrl}/events/pending`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching pending events:', error);
        setError('Failed to fetch pending events. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingEvents();
  }, []);

  // Validate and convert latitude and longitude to numbers
  const validateCoordinates = (latitude, longitude) => {
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    if (isNaN(lat) || isNaN(lng)) {
      throw new Error('Invalid latitude or longitude');
    }
    if (lat < -90 || lat > 90) {
      throw new Error('Latitude must be between -90 and 90');
    }
    if (lng < -180 || lng > 180) {
      throw new Error('Longitude must be between -180 and 180');
    }
    return [lat, lng];
  };

  // Validate date format and return timestamp in seconds
  const validateAndGetTimestamp = (dateString, fieldName) => {
    if (!dateString) return null; // Return null for optional fields like event_end_date

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid ${fieldName}: ${dateString}. Must be a valid date.`);
    }
    return Math.floor(date.getTime() / 1000);
  };

  // Update an existing event (original)
  const updateEvent = async (event, status, message) => {
    try {
      const [lat, lng] = validateCoordinates(selectedEvent.latitude, selectedEvent.longitude);
      const eventDateTs = validateAndGetTimestamp(selectedEvent.event_date, 'event_date');
      const eventEndDateTs = validateAndGetTimestamp(selectedEvent.event_end_date, 'event_end_date');

      const eventDate = moment(selectedEvent.event_date).utc().format();
      const eventEndDate = selectedEvent.event_end_date
        ? moment(selectedEvent.event_end_date).utc().format()
        : null;

      const eventData = {
        id: event.id,
        eventName: selectedEvent.event_name,
        eventDate: eventDate,
        eventEndDate: eventEndDate,
        eventLocation: selectedEvent.event_location,
        eventDescription: selectedEvent.event_description || '',
        eventImageLink: selectedEvent.event_image_link || '',
        latitude: lat,
        longitude: lng,
        websiteLink: selectedEvent.website_link || '',
        eventSummary: selectedEvent.event_summary || '',
        language: selectedEvent.language,
        type: selectedEvent.type,
        status: status,
        comments: status === 'rejected' ? comments : selectedEvent.comments,
        userName: selectedEvent.user_name,
      };

      const response = await axios.put(`${apiUrl}/events/${event.id}`, eventData);
      setApprovalStatus(message || `Event ${response.data.event_name} updated successfully!`);
      setEvents(events.filter((ev) => ev.id !== event.id));
      setSelectedEvent(null);
      setTranslatedEvent(null);
    } catch (error) {
      console.error(`Error updating event:`, error);
      setApprovalStatus(`Error updating event: ${error.response?.data || error.message}`);
    }
  };

  // Save translated event (either as "pending" or "approved")
  const saveTranslatedEvent = async (status, message) => {
    try {
      const [lat, lng] = validateCoordinates(translatedEvent.latitude, translatedEvent.longitude);
      const eventDateTs = validateAndGetTimestamp(translatedEvent.event_date, 'event_date');
      const eventEndDateTs = validateAndGetTimestamp(translatedEvent.event_end_date, 'event_end_date');

      const eventDate = moment(translatedEvent.event_date).utc().format();
      const eventEndDate = translatedEvent.event_end_date
        ? moment(translatedEvent.event_end_date).utc().format()
        : null;

      const translatedEventData = {
        eventName: translatedEvent.event_name,
        eventDate: eventDate,
        eventEndDate: eventEndDate,
        eventLocation: translatedEvent.event_location,
        eventDescription: translatedEvent.event_description || '',
        eventImageLink: translatedEvent.event_image_link || '',
        latitude: lat,
        longitude: lng,
        websiteLink: translatedEvent.website_link || '',
        eventSummary: translatedEvent.event_summary || '',
        language: translatedEvent.language,
        type: translatedEvent.type,
        status: status,
        userName: 'other',
      };

      const response = await axios.post(`${apiUrl}/events`, translatedEventData);
      setApprovalStatus(message || `Translated event saved with ID: ${response.data.id}`);
      setTranslatedEvent(null);
      if (status === 'pending') {
        const updatedEvents = await axios.get(`${apiUrl}/events/pending`);
        setEvents(updatedEvents.data);
      }
    } catch (error) {
      console.error(`Error saving translated event as ${status}:`, error);
      setApprovalStatus(`Error saving translated event as ${status}: ${error.response?.data || error.message}`);
    }
  };

  // Handle approval
  const handleApprove = async (isOriginal = true) => {
    if (isOriginal && selectedEvent) {
      await updateEvent(selectedEvent, 'approved', `Event ${selectedEvent.event_name} approved successfully!`);
    } else if (!isOriginal && translatedEvent) {
      await saveTranslatedEvent('approved', 'Translated event approved and saved!');
    }
  };

  // Handle rejection
  const handleReject = async (isOriginal = true) => {
    if (isOriginal && selectedEvent) {
      await updateEvent(selectedEvent, 'rejected', `Event ${selectedEvent.event_name} rejected successfully!`);
    } else if (!isOriginal && translatedEvent) {
      setTranslatedEvent(null);
      setApprovalStatus('Translated event discarded.');
    }
  };

  // Handle saving changes
  const handleSaveChanges = async (isOriginal = true) => {
    if (isOriginal && selectedEvent) {
      await updateEvent(selectedEvent, 'pending', 'Original event changes saved successfully!');
    } else if (!isOriginal && translatedEvent) {
      await saveTranslatedEvent('pending', 'Translated event saved as pending!');
    }
  };

  // Handle field changes
  const handleChange = (e, field, isOriginal = true) => {
    const value = e.target.value;
    if (isOriginal) {
      setSelectedEvent((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    } else {
      setTranslatedEvent((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  // Select an event for review
  const handleSelectEvent = (event) => {
    setSelectedEvent({
      ...event,
      event_date: moment.utc(event.event_date).local().format('YYYY-MM-DDTHH:mm'),
      event_end_date: event.event_end_date
        ? moment.utc(event.event_end_date).local().format('YYYY-MM-DDTHH:mm')
        : '',
      language: event.language || 'TH',
    });
    setComments('');
    setTranslatedEvent(null);
  };

  // Translate text using Google Translate API
  const translateText = async (text, targetLanguage = 'en') => {
    if (!text) return ''; // Handle empty text gracefully

    try {
      const response = await axios.post(translateApiUrl, null, {
        params: {
          q: text,
          target: targetLanguage,
          key: process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY,
        },
      });
      return response.data.data.translations[0].translatedText;
    } catch (error) {
      console.error('Error translating text:', error);
      return text; // Fallback to original text if translation fails
    }
  };

  // Translate to target language (English or Chinese)
  const handleTranslateToLanguage = async (targetLanguage) => {
    if (!selectedEvent) return;

    const languageCode = targetLanguage === 'EN' ? 'en' : 'zh';
    const languageLabel = targetLanguage === 'EN' ? 'EN' : 'CN';

    setIsTranslating(true);

    try {
      const [translatedName, translatedDescription, translatedSummary, translatedLocation] =
        await Promise.all([
          translateText(selectedEvent.event_name, languageCode),
          translateText(selectedEvent.event_description, languageCode),
          translateText(selectedEvent.event_summary, languageCode),
          translateText(selectedEvent.event_location, languageCode),
        ]);

      const translatedData = {
        ...selectedEvent,
        event_name: translatedName,
        event_description: translatedDescription,
        event_summary: translatedSummary,
        event_location: translatedLocation,
        language: languageLabel,
      };

      setTranslatedEvent(translatedData);
    } catch (error) {
      console.error(`Error translating to ${languageLabel}:`, error);
      setApprovalStatus(`Translation to ${languageLabel} failed. Please try again.`);
    } finally {
      setIsTranslating(false);
    }
  };

  // Translate to English
  const handleTranslate = () => handleTranslateToLanguage('EN');

  // Translate to Chinese
  const handleTranslateToChinese = () => handleTranslateToLanguage('CN');

  // Event type options
  const eventTypes = [
    { value: 'Product & Service Fair', label: { EN: 'Product & Service Fair', TH: 'งานแสดงสินค้าและบริการ' } },
    { value: 'Promotions', label: { EN: 'Promotions', TH: 'โปรโมชั่นและส่วนลด' } },
    { value: 'Arts & Films', label: { EN: 'Arts & Films', TH: 'ศิลปะและภาพยนตร์' } },
    { value: 'Live Performances', label: { EN: 'Live Performances', TH: 'คอนเสิร์ตและการแสดงสด' } },
    { value: 'Sports & Competitions', label: { EN: 'Sports & Competitions', TH: 'กีฬาและการแข่งขัน' } },
    { value: 'Travel & Photos', label: { EN: 'Travel & Photos', TH: 'ท่องเที่ยวและภาพถ่าย' } },
    { value: 'Business & Investment', label: { EN: 'Business & Investment', TH: 'ธุรกิจและการลงทุน' } },
    { value: 'Education & Family', label: { EN: 'Education & Family', TH: 'การศึกษาและครอบครัว' } },
    { value: 'Other', label: { EN: 'Other', TH: 'อื่นๆ' } },
  ];

  // Language options
  const languageOptions = [
    { value: 'TH', label: 'Thai' },
    { value: 'EN', label: 'English' },
    { value: 'CN', label: 'Chinese' },
  ];

  return (
    <Container sx={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Approve or Reject Events
      </Typography>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
          <Typography variant="body1" sx={{ ml: 2 }}>
            Loading events...
          </Typography>
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
          flexDirection="column"
        >
          <Typography variant="h6" color="error" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">{error}</Typography>
        </Box>
      ) : events.length > 0 ? (
        <Grid container spacing={3}>
          {events.map((event) => (
            <Grid item xs={12} key={event.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Original Event (Thai)</Typography>
                  <TextField
                    label="Event Name"
                    fullWidth
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.event_name
                        : event.event_name
                    }
                    onChange={(e) => handleChange(e, 'event_name')}
                  />
                  <TextField
                    label="Event Date"
                    type="datetime-local"
                    fullWidth
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.event_date
                        : moment.utc(event.event_date).local().format('YYYY-MM-DDTHH:mm')
                    }
                    onChange={(e) => handleChange(e, 'event_date')}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Event End Date"
                    type="datetime-local"
                    fullWidth
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.event_end_date
                        : event.event_end_date
                        ? moment.utc(event.event_end_date).local().format('YYYY-MM-DDTHH:mm')
                        : ''
                    }
                    onChange={(e) => handleChange(e, 'event_end_date')}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Event Location"
                    fullWidth
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.event_location
                        : event.event_location
                    }
                    onChange={(e) => handleChange(e, 'event_location')}
                  />
                  <TextField
                    label="Latitude"
                    fullWidth
                    margin="normal"
                    type="number"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.latitude
                        : event.latitude
                    }
                    onChange={(e) => handleChange(e, 'latitude')}
                  />
                  <TextField
                    label="Longitude"
                    fullWidth
                    margin="normal"
                    type="number"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.longitude
                        : event.longitude
                    }
                    onChange={(e) => handleChange(e, 'longitude')}
                  />
                  <TextField
                    label="Website Link"
                    fullWidth
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.website_link
                        : event.website_link
                    }
                    onChange={(e) => handleChange(e, 'website_link')}
                  />
                  <TextField
                    label="Event Description"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.event_description
                        : event.event_description
                    }
                    onChange={(e) => handleChange(e, 'event_description')}
                  />
                  <TextField
                    label="Event Summary"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.event_summary
                        : event.event_summary
                    }
                    onChange={(e) => handleChange(e, 'event_summary')}
                  />
                  <TextField
                    label="Image Link"
                    fullWidth
                    margin="normal"
                    value={
                      selectedEvent && selectedEvent.id === event.id
                        ? selectedEvent.event_image_link
                        : event.event_image_link
                    }
                    onChange={(e) => handleChange(e, 'event_image_link')}
                  />
                  {selectedEvent &&
                    selectedEvent.id === event.id &&
                    selectedEvent.event_image_link && (
                      <img
                        src={selectedEvent.event_image_link}
                        alt="Event"
                        style={{
                          width: '100%',
                          maxHeight: '200px',
                          objectFit: 'cover',
                          marginTop: '10px',
                        }}
                      />
                    )}
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Language</InputLabel>
                    <Select
                      value={
                        selectedEvent && selectedEvent.id === event.id
                          ? selectedEvent.language
                          : 'TH'
                      }
                      onChange={(e) => handleChange(e, 'language')}
                    >
                      {languageOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={
                        selectedEvent && selectedEvent.id === event.id
                          ? selectedEvent.type
                          : event.type
                      }
                      onChange={(e) => handleChange(e, 'type')}
                    >
                      {eventTypes.map((eventType, index) => (
                        <MenuItem key={index} value={eventType.value}>
                          {eventType.label.EN}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedEvent && selectedEvent.latitude && selectedEvent.longitude && (
                    <iframe
                      width="100%"
                      height="300"
                      frameBorder="0"
                      style={{ border: 0, marginTop: '10px' }}
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${selectedEvent.latitude},${selectedEvent.longitude}&zoom=14`}
                      allowFullScreen
                    ></iframe>
                  )}
                  <TextField
                    label="Comments"
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Add comments for rejection"
                  />
                </CardContent>

                <CardActions>
                  {selectedEvent && selectedEvent.id === event.id ? (
                    <Box width="100%">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleApprove(true)}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleReject(true)}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveChanges(true)}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Save Changes
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleTranslate}
                        disabled={isTranslating}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {isTranslating ? 'Translating...' : 'Translate to English'}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleTranslateToChinese}
                        disabled={isTranslating}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {isTranslating ? 'Translating...' : 'Translate to Chinese'}
                      </Button>
                    </Box>
                  ) : (
                    <Button onClick={() => handleSelectEvent(event)}>Review</Button>
                  )}
                </CardActions>

                {translatedEvent && selectedEvent && selectedEvent.id === event.id && (
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      Translated Event ({translatedEvent.language === 'CN' ? 'Chinese' : 'English'})
                    </Typography>
                    <TextField
                      label="Translated Event Name"
                      fullWidth
                      margin="normal"
                      value={translatedEvent.event_name}
                      onChange={(e) => handleChange(e, 'event_name', false)}
                    />
                    <TextField
                      label="Translated Event Description"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={4}
                      value={translatedEvent.event_description}
                      onChange={(e) => handleChange(e, 'event_description', false)}
                    />
                    <TextField
                      label="Translated Event Summary"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={4}
                      value={translatedEvent.event_summary}
                      onChange={(e) => handleChange(e, 'event_summary', false)}
                    />
                    <TextField
                      label="Translated Event Location"
                      fullWidth
                      margin="normal"
                      value={translatedEvent.event_location}
                      onChange={(e) => handleChange(e, 'event_location', false)}
                    />
                    <TextField
                      label="Latitude"
                      fullWidth
                      margin="normal"
                      type="number"
                      value={translatedEvent.latitude}
                      onChange={(e) => handleChange(e, 'latitude', false)}
                    />
                    <TextField
                      label="Longitude"
                      fullWidth
                      margin="normal"
                      type="number"
                      value={translatedEvent.longitude}
                      onChange={(e) => handleChange(e, 'longitude', false)}
                    />
                    <TextField
                      label="Website Link"
                      fullWidth
                      margin="normal"
                      value={translatedEvent.website_link}
                      onChange={(e) => handleChange(e, 'website_link', false)}
                    />
                    <TextField
                      label="Image Link"
                      fullWidth
                      margin="normal"
                      value={translatedEvent.event_image_link}
                      onChange={(e) => handleChange(e, 'event_image_link', false)}
                    />
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Language</InputLabel>
                      <Select
                        value={translatedEvent.language}
                        onChange={(e) => handleChange(e, 'language', false)}
                      >
                        {languageOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={translatedEvent.type}
                        onChange={(e) => handleChange(e, 'type', false)}
                      >
                        {eventTypes.map((eventType, index) => (
                          <MenuItem key={index} value={eventType.value}>
                            {eventType.label.EN}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </CardContent>
                )}

                {translatedEvent && selectedEvent && selectedEvent.id === event.id && (
                  <CardActions>
                    <Box width="100%">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleApprove(false)}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Approve Translated
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleReject(false)}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Reject Translated
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveChanges(false)}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Save Translated Changes
                      </Button>
                    </Box>
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
          flexDirection="column"
        >
          <Typography variant="h6" color="textSecondary" gutterBottom>
            There is no pending event
          </Typography>
          <Typography variant="body1" color="textSecondary">
            All events have been reviewed. Check back later for new submissions.
          </Typography>
        </Box>
      )}

      {approvalStatus && (
        <Typography variant="body1" sx={{ mt: 2, color: approvalStatus.includes('Error') ? 'error.main' : 'success.main' }}>
          {approvalStatus}
        </Typography>
      )}
    </Container>
  );
};

export default ApprovalPage;