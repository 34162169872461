// src/pages/EventMap.js
import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useParams, useNavigate } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const EventMap = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(true);
  const navigate = useNavigate();
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(`/events/${id}`);
        const data = await response.json();
        setEvent(data);
        setMapCenter({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEvent();
  }, [id]);

  const handleMapClick = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
    }
  };

  const handleMarkerClick = () => {
    setShowInfoWindow(true);
  };

  const handleInfoWindowClick = () => {
    if (event && event.id) {
      navigate(`/event/${event.id}`);
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={10}
      mapTypeId={window.google.maps.MapTypeId.ROADMAP}
      onClick={handleMapClick}
      ref={mapRef}
    >
      {mapCenter && (
        <Marker position={mapCenter} onClick={handleMarkerClick}>
          {showInfoWindow && event && (
            <InfoWindow position={mapCenter}>
              <div onClick={handleInfoWindowClick}>
                <h3>{event.event_name}</h3>
                <p>{event.event_location}</p>
                <img src={event.event_image_link} alt={event.event_name} style={{ width: '100px', height: 'auto' }} />
              </div>
            </InfoWindow>
          )}
        </Marker>
      )}
    </GoogleMap>
  );
};

export default EventMap;
