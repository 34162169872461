import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from '@mui/material';
import axios from 'axios';
import { format, parseISO, isSameMonth, subMonths } from 'date-fns';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const SubmittedList = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${apiUrl}/groupedEvents`, {
        params: {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      });
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const groupEventsByMonthStatusAndUser = (events) => {
    const currentMonth = new Date();
    const previousMonth = subMonths(currentMonth, 1);
    return events.reduce((acc, event) => {
      const eventMonth = parseISO(event.submitted_date);
      if (isSameMonth(eventMonth, currentMonth) || isSameMonth(eventMonth, previousMonth)) {
        const monthKey = format(eventMonth, 'MMMM yyyy');
        if (!acc[monthKey]) {
          acc[monthKey] = {};
        }
        const userName = event.user_name || 'Unknown';
        if (!acc[monthKey][userName]) {
          acc[monthKey][userName] = {};
        }
        const status = event.status;
        if (!acc[monthKey][userName][status]) {
          acc[monthKey][userName][status] = [];
        }
        acc[monthKey][userName][status].push(event);
      }
      return acc;
    }, {});
  };

  const titles = {
    EN: {
      eventName: 'Event Name',
      eventLocation: 'Event Location',
      eventDate: 'Event Date',
      eventEndDate: 'Event End Date',
      userName: 'User / ชื่อผู้ส่ง',
      eventStatus: 'Status / สถานะ',
      submittedDate: 'Submitted Date',
      comments: 'Comments'
    }
  };

  const groupedEvents = groupEventsByMonthStatusAndUser(events);
  const currentTitles = titles.EN;

  return (
    <Container style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Submitted Events/ รายชื่อกิจกรรมที่ส่งเข้ามา
      </Typography>
      {Object.keys(groupedEvents).map((month) => (
        <Box key={month} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Typography variant="h6" gutterBottom>{month}</Typography>
          {Object.keys(groupedEvents[month]).map((userName) => (
            <Box key={userName} style={{ marginBottom: '20px' }}>
              <Typography variant="subtitle1" gutterBottom>{`User / ชื่อผู้ส่ง : ${userName}`}</Typography>
              {Object.keys(groupedEvents[month][userName]).map((status) => (
                <Box key={status} style={{ marginBottom: '20px' }}>
                  <Typography variant="subtitle1" gutterBottom>{`Status / สถานะ : ${status} (${groupedEvents[month][userName][status].length} events/งาน)`}</Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow>
                          <TableCell>No.</TableCell>
                          <TableCell>{currentTitles.eventName}</TableCell>
                          <TableCell>{currentTitles.eventLocation}</TableCell>
                          <TableCell>{currentTitles.eventDate}</TableCell>
                          <TableCell>{currentTitles.eventEndDate}</TableCell>
                          <TableCell>{currentTitles.userName}</TableCell>
                          <TableCell>{currentTitles.submittedDate}</TableCell>
                          <TableCell>{currentTitles.eventStatus}</TableCell>
                          <TableCell>{currentTitles.comments}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupedEvents[month][userName][status].map((event, index) => (
                          <TableRow key={event.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{event.event_name}</TableCell>
                            <TableCell>{event.event_location}</TableCell>
                            <TableCell>{format(parseISO(event.event_date), 'yyyy-MM-dd HH:mm')}</TableCell>
                            <TableCell>{event.event_end_date ? format(parseISO(event.event_end_date), 'yyyy-MM-dd HH:mm') : ''}</TableCell>
                            <TableCell>{event.user_name}</TableCell>
                            <TableCell>{format(parseISO(event.submitted_date), 'yyyy-MM-dd HH:mm')}</TableCell>
                            <TableCell>{event.status}</TableCell>
                            <TableCell>{event.comments}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ))}
    </Container>
  );
};

export default SubmittedList;
