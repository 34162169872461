import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Menu,
  TextField,
  Box
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Autosuggest from 'react-autosuggest';
import ThaiFlag from './thai-flag.png';
import EnglishFlag from './english-flag.png';
import ChinaFlag from './china-flag.png';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchIcon from '@mui/icons-material/Search';
import MenuItemMui from '@mui/material/MenuItem';
import Typesense from 'typesense';
import { debounce } from 'lodash';
import axios from 'axios'; // Add axios for API calls

// Basic CSS for Autosuggest
const autosuggestStyles = {
  container: {
    position: 'relative',
    margin: '0 16px',
    width: '375px',
    height: '40px',
  },
  input: {
    width: '100%',
    padding: '8px 12px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    backgroundColor: '#ffffff',
    height: '40px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    }
  },
  suggestionsContainer: {
    display: 'block',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 1000,
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    maxHeight: '200px',
    overflowY: 'auto'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestion: {
    padding: '8px 12px',
    cursor: 'pointer',
    color: 'black'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
    color: 'black'
  }
};

// Debug environment variables
console.log('Environment Variables:', {
  host: process.env.REACT_APP_TYPESENSE_HOST,
  port: process.env.REACT_APP_TYPESENSE_PORT,
  protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL,
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY ? '[PRESENT]' : '[MISSING]'
});

const TYPESENSE_HOST = process.env.REACT_APP_TYPESENSE_HOST;
const TYPESENSE_PORT = parseInt(process.env.REACT_APP_TYPESENSE_PORT || '443', 10);
const TYPESENSE_PROTOCOL = process.env.REACT_APP_TYPESENSE_PROTOCOL || 'https';
const TYPESENSE_API_KEY = process.env.REACT_APP_TYPESENSE_API_KEY;

if (!TYPESENSE_HOST || !TYPESENSE_API_KEY) {
  throw new Error('Missing required environment variables: REACT_APP_TYPESENSE_HOST and REACT_APP_TYPESENSE_API_KEY must be set.');
}

// Initialize Typesense client
const typesenseClient = new Typesense.Client({
  nodes: [
    {
      host: TYPESENSE_HOST,
      port: TYPESENSE_PORT,
      protocol: TYPESENSE_PROTOCOL
    }
  ],
  apiKey: TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 5,
  cacheSearchResultsForSeconds: 60
});

console.log('Typesense client config:', {
  host: TYPESENSE_HOST,
  port: TYPESENSE_PORT,
  protocol: TYPESENSE_PROTOCOL,
  apiKey: TYPESENSE_API_KEY ? '[PRESENT]' : '[MISSING]'
});

const Header = ({
  setCoordinates,
  type,
  setType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  language,
  setLanguage,
  updatePlaces,
  mapBounds
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const hasInitialSearchRun = useRef(false);

  console.log('Header received mapBounds:', mapBounds);

  // Local event types (localized)
  const eventTypes = {
    EN: [
      'All',
      'Product & Service Fair',
      'Promotions',
      'Arts & Films',
      'Live Performances',
      'Sports & Competitions',
      'Travel & Photos',
      'Business & Investment',
      'Education & Family',
      'Other'
    ],
    TH: [
      'ทั้งหมด',
      'งานแสดงสินค้าและบริการ',
      'โปรโมชั่นและส่วนลด',
      'ศิลปะและภาพยนตร์',
      'คอนเสิร์ตและการแสดงสด',
      'กีฬาและการแข่งขัน',
      'ท่องเที่ยวและภาพถ่าย',
      'ธุรกิจและการลงทุน',
      'การศึกษาและครอบครัว',
      'อื่นๆ'
    ],
    CN: [
      '全部',
      '产品与服务展',
      '促销活动',
      '艺术与电影',
      '现场表演',
      '体育与竞赛',
      '旅行与摄影',
      '商业与投资',
      '教育与家庭',
      '其他'
    ]
  };

  // Translated header text
  const getEventMapText = () =>
    language === 'TH'
      ? 'กิจกรรมรอบตัวคุณ'
      : language === 'CN'
      ? '您附近的活动'
      : 'Events around you';

  // Helper: Build bounding-box filter using a radius-based approach
  const buildBoundingBoxFilter = (bounds) => {
    if (
      !bounds ||
      !bounds.sw ||
      !bounds.ne ||
      typeof bounds.sw.lat !== 'number' ||
      typeof bounds.sw.lng !== 'number' ||
      typeof bounds.ne.lat !== 'number' ||
      typeof bounds.ne.lng !== 'number'
    ) {
      console.warn('Map bounds not available or invalid:', bounds);
      return null;
    }

    const minLat = Math.min(bounds.sw.lat, bounds.ne.lat);
    const maxLat = Math.max(bounds.sw.lat, bounds.ne.lat);
    const minLng = Math.min(bounds.sw.lng, bounds.ne.lng);
    const maxLng = Math.max(bounds.sw.lng, bounds.ne.lng);

    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    const toRadians = (degrees) => (degrees * Math.PI) / 180;
    const earthRadiusKm = 6371;

    const dLat = toRadians(maxLat - minLat);
    const dLng = toRadians(maxLng - minLng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(minLat)) * Math.cos(toRadians(maxLat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceKm = earthRadiusKm * c;

    const radiusKm = (distanceKm / 2) + 1;
    const finalRadiusKm = Math.max(radiusKm, 5);

    const boundingBox = `location:([${centerLat}, ${centerLng}], radius: ${finalRadiusKm} km)`;
    console.log('Built bounding box filter (radius-based):', boundingBox);
    return boundingBox;
  };

  // Helper: Build date range filter for Typesense
  const buildDateRangeFilter = (startDate, endDate) => {
    if (!startDate && !endDate) return null;

    const filters = [];

    const normalizeStartOfDay = (date) => {
      const d = new Date(date);
      d.setHours(0, 0, 0, 0);
      return Math.floor(d.getTime() / 1000);
    };

    const normalizeEndOfDay = (date) => {
      const d = new Date(date);
      d.setHours(23, 59, 59, 999);
      return Math.floor(d.getTime() / 1000);
    };

    if (startDate) {
      const startTs = normalizeStartOfDay(startDate);
      filters.push(`event_end_date_ts:>=${startTs}`);
    }

    if (endDate) {
      const endTs = normalizeEndOfDay(endDate);
      filters.push(`event_date_ts:<=${endTs}`);
    }

    const dateFilter = filters.join(' && ');
    console.log('Built date range filter:', dateFilter);
    return dateFilter;
  };

  // Helper: Build search parameters for Typesense
  const buildSearchParams = (query, isAutosuggest = false) => {
    const searchParams = {
      q: query || '*',
      query_by: 'event_name,searchableContent',
      per_page: isAutosuggest ? 10 : 20,
      typo_tokens_threshold: 1,
      num_typos: 1,
      prefix: true,
      sort_by: '_text_match:desc'
    };

    const filters = [];
    if (language) {
      filters.push(`language:="${language}"`);
    }
    if (type && type !== 'All' && type !== 'ทั้งหมด' && type !== '全部') {
      filters.push(`type:="${type}"`);
    }

    const boundingBoxFilter = buildBoundingBoxFilter(mapBounds);
    if (boundingBoxFilter) {
      filters.push(boundingBoxFilter);
    }

    const dateFilter = buildDateRangeFilter(startDate, endDate);
    if (dateFilter) {
      filters.push(dateFilter);
    }

    const filterBy = filters.join(' && ');
    if (filterBy) {
      searchParams.filter_by = filterBy;
    }

    return searchParams;
  };

  // Fetch full event details from PostgreSQL
  const fetchEventsFromPostgres = async (eventIds) => {
    if (!eventIds || eventIds.length === 0) {
      console.log('No event IDs to fetch from PostgreSQL.');
      return [];
    }

    try {
      console.log('Fetching events from PostgreSQL with IDs:', eventIds);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/events/batch`, {
        eventIds: eventIds,
      });

      const events = response.data.map((event) => ({
        id: event.id,
        event_name: event.event_name,
        latitude: parseFloat(event.latitude), // Ensure latitude is a number
        longitude: parseFloat(event.longitude), // Ensure longitude is a number
        event_date: event.event_date,
        event_end_date: event.event_end_date,
        event_location: event.event_location,
        event_image_link: event.event_image_link,
      }));

      console.log('Fetched events from PostgreSQL:', events);
      return events;
    } catch (error) {
      console.error('Error fetching events from PostgreSQL:', error.message, error.response?.data);
      return [];
    }
  };

  // Wrap getSuggestions in useCallback
  const getSuggestions = useCallback(async (value) => {
    console.log('getSuggestions called with value:', value);
    if (!value.trim()) {
      console.log('Search term is empty; clearing suggestions.');
      setSuggestions([]);
      return;
    }
    if (!mapBounds || !mapBounds.sw || !mapBounds.ne) {
      console.log('Map bounds not ready; skipping autosuggest search. mapBounds:', mapBounds);
      setSuggestions([]);
      return;
    }

    const searchParams = buildSearchParams(value, true);
    console.log('Autosuggest search parameters:', searchParams);

    try {
      console.log('Sending request to Typesense...');
      const response = await typesenseClient
        .collections('events')
        .documents()
        .search(searchParams);

      console.log('Autosuggest response:', response);
      if (!response.hits || response.hits.length === 0) {
        console.log('No suggestions found.');
        setSuggestions([]);
        return;
      }

      const hits = response.hits.map((hit) => hit.document);
      const unique = [];
      const seen = new Set();

      hits.forEach((doc) => {
        const eventName = doc.event_name;
        if (!eventName) return;
        if (!seen.has(eventName)) {
          seen.add(eventName);
          unique.push({ ...doc, displayed_name: eventName });
        }
      });

      console.log('Unique Suggestions:', unique);
      setSuggestions(unique);
    } catch (error) {
      console.error('Error fetching suggestions from Typesense:', error);
      setSuggestions([]);
    }
  }, [mapBounds, language, type, startDate, endDate]);

  // Memoize the debounced version of getSuggestions
  const getSuggestionsDebounced = useMemo(() => debounce(getSuggestions, 300), [getSuggestions]);

  // Clean up the debounced function on unmount
  useEffect(() => {
    return () => {
      getSuggestionsDebounced.cancel();
    };
  }, [getSuggestionsDebounced]);

  // Autosuggest event handlers
  const onChange = (event, { newValue }) => {
    console.log('Autosuggest onChange:', newValue);
    setSearchTerm(newValue);
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    console.log('onSuggestionsFetchRequested triggered with value:', value);
    getSuggestionsDebounced(value);
  };
  const onSuggestionsClearRequested = () => {
    console.log('onSuggestionsClearRequested triggered');
    setSuggestions([]);
  };
  const onSuggestionSelected = (event, { suggestion }) => {
    console.log('Suggestion selected:', suggestion);
    const eventName = suggestion.displayed_name || suggestion.event_name;
    setSearchTerm(eventName);
  };
  const renderSuggestion = (suggestion) => (
    <div style={autosuggestStyles.suggestion}>
      {suggestion.displayed_name || suggestion.event_name || 'Unknown event'}
    </div>
  );
  const inputProps = {
    placeholder:
      language === 'TH'
        ? 'ค้นหากิจกรรม'
        : language === 'CN'
        ? '搜索活动'
        : 'Search for events',
    value: searchTerm,
    onChange: onChange,
    style: autosuggestStyles.input
  };

  // Manual search: triggered by the search button
  const handleSearch = async () => {
    if (!mapBounds || !mapBounds.sw || !mapBounds.ne) {
      console.warn('Map bounds not ready; manual search delayed.');
      return;
    }

    // Step 1: Filter events using Typesense
    const searchParams = buildSearchParams(searchTerm, false);
    console.log('Manual search parameters for Typesense:', searchParams);

    try {
      const result = await typesenseClient
        .collections('events')
        .documents()
        .search(searchParams);

      const hits = result.hits || [];
      const eventIds = hits.map((hit) => hit.document.id);
      console.log(`Found ${eventIds.length} event IDs from Typesense:`, eventIds);

      // Step 2: Fetch full event details from PostgreSQL using the event IDs
      const events = await fetchEventsFromPostgres(eventIds);
      console.log(`Fetched ${events.length} events from PostgreSQL:`, events);

      // Step 3: Update the map with the fetched events
      if (events.length === 0) {
        console.warn('No events fetched from PostgreSQL; map will not update with markers.');
      }
      updatePlaces(events);
    } catch (error) {
      console.error('Error during manual search:', error);
      updatePlaces([]);
    }
  };

  // Initial search when mapBounds becomes available (run only once)
  useEffect(() => {
    if (
      !hasInitialSearchRun.current &&
      mapBounds &&
      mapBounds.sw &&
      mapBounds.ne &&
      typeof mapBounds.sw.lat === 'number' &&
      typeof mapBounds.sw.lng === 'number' &&
      typeof mapBounds.ne.lat === 'number' &&
      typeof mapBounds.ne.lng === 'number'
    ) {
      console.log('Running initial search with mapBounds:', mapBounds);
      hasInitialSearchRun.current = true;

      const searchParams = {
        q: '*',
        query_by: 'event_name,searchableContent',
        per_page: 20,
        sort_by: '_text_match:desc'
      };

      const filters = [];
      if (language) {
        filters.push(`language:="${language}"`);
      }
      const boundingBoxFilter = buildBoundingBoxFilter(mapBounds);
      if (boundingBoxFilter) {
        filters.push(boundingBoxFilter);
      }

      const filterBy = filters.join(' && ');
      if (filterBy) {
        searchParams.filter_by = filterBy;
      }

      typesenseClient
        .collections('events')
        .documents()
        .search(searchParams)
        .then(async (result) => {
          const hits = result.hits || [];
          const eventIds = hits.map((hit) => hit.document.id);
          console.log(`Initial search found ${eventIds.length} event IDs from Typesense.`);

          const events = await fetchEventsFromPostgres(eventIds);
          console.log(`Initial search fetched ${events.length} events from PostgreSQL.`);
          updatePlaces(events);
        })
        .catch((error) => {
          console.error('Error during initial search:', error);
          updatePlaces([]);
        });
    }
  }, [mapBounds, language, updatePlaces]);

  // Menu/UI handlers
  const handleMenuOpen = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const handleEventAddingRequest = () => {
    window.open('/eventinput', '_blank');
    handleMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ color: 'white', mr: 2 }}>
          {getEventMapText()}
        </Typography>

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(suggestion) =>
            suggestion.displayed_name || suggestion.event_name || 'Unknown event'
          }
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={autosuggestStyles}
        />

        <FormControl sx={{ mx: 1, bgcolor: 'white', borderRadius: '4px', minWidth: 120 }}>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            sx={{ height: '40px' }}
          >
            {eventTypes[language].map((label, idx) => (
              <MenuItem key={idx} value={eventTypes.EN[idx]}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ bgcolor: 'white', borderRadius: '4px', mx: 1, width: '150px' }}
                size="small"
              />
            )}
          />
          <DatePicker
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ bgcolor: 'white', borderRadius: '4px', mx: 1, width: '150px' }}
                size="small"
              />
            )}
          />
        </LocalizationProvider>

        <IconButton sx={{ bgcolor: 'white', mx: 1, height: '40px' }} onClick={handleSearch}>
          <SearchIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
          <IconButton sx={{ p: 0, mx: 0.5 }} onClick={() => setLanguage('TH')}>
            <img src={ThaiFlag} alt="TH" width={30} />
          </IconButton>
          <IconButton sx={{ p: 0, mx: 0.5 }} onClick={() => setLanguage('EN')}>
            <img src={EnglishFlag} alt="EN" width={30} />
          </IconButton>
          <IconButton sx={{ p: 0, mx: 0.5 }} onClick={() => setLanguage('CN')}>
            <img src={ChinaFlag} alt="CN" width={30} />
          </IconButton>
        </Box>

        <IconButton onClick={handleMenuOpen}>
          <ErrorOutlineIcon sx={{ color: 'white' }} />
        </IconButton>
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
          <MenuItemMui onClick={handleEventAddingRequest}>
            Event Adding Request
          </MenuItemMui>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;