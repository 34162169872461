import React, { useState } from 'react';
import {
  Container,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import axios from 'axios';

const EventUpload = () => {
  const [file, setFile] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [previewErrors, setPreviewErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Expected fields for validation
  const requiredFields = [
    'event_name',
    'event_date',
    'event_location',
    'latitude',
    'longitude',
    'language',
    'type',
  ];
  const optionalFields = [
    'event_end_date',
    'event_description',
    'event_image_link',
    'website_link',
    'event_summary',
    'user_name',
    'status',
  ];

  // Handle file selection and immediate preview
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setPreviewData([]);
    setPreviewErrors([]);
    setSuccessMessage('');
    setErrorMessage('');

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        setFile(selectedFile);
        try {
          const data = await parseFile(selectedFile);
          const deduplicatedData = deduplicateEvents(data); // Deduplicate events in the file
          setPreviewData(deduplicatedData);
          const errors = validateEvents(deduplicatedData);
          setPreviewErrors(errors);
        } catch (error) {
          setErrorMessage(error.message);
          setFile(null);
        }
      } else {
        setErrorMessage('Please upload a CSV or Excel file (.csv, .xlsx, or .xls).');
        setFile(null);
      }
    }
  };

  // Parse file function returns data array
  const parseFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'csv') {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const data = result.data;
            if (data.length === 0) {
              reject(new Error('The CSV file is empty.'));
              return;
            }
            resolve(data);
          },
          error: (error) => {
            reject(new Error(`Error parsing CSV file: ${error.message}`));
          },
        });
      } else if (['xlsx', 'xls'].includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          if (jsonData.length === 0) {
            reject(new Error('The Excel file is empty.'));
            return;
          }
          resolve(jsonData);
        };
        reader.onerror = () => {
          reject(new Error('Error reading Excel file.'));
        };
        reader.readAsArrayBuffer(file);
      }
    });
  };

  // Deduplicate events in the file
  const deduplicateEvents = (events) => {
    const seen = new Set();
    const deduplicated = [];
    const errors = [];

    events.forEach((event, index) => {
      const key = [
        event.event_name,
        event.event_date,
        event.event_end_date || '',
        event.event_location,
        event.latitude,
        event.longitude,
        event.language,
        event.type,
      ].join('|');

      if (seen.has(key)) {
        errors.push(`Row ${index + 1}: Duplicate event detected: "${event.event_name}" on ${event.event_date} at ${event.event_location}.`);
      } else {
        seen.add(key);
        deduplicated.push(event);
      }
    });

    if (errors.length > 0) {
      setPreviewErrors((prev) => [...prev, ...errors]);
    }

    return deduplicated;
  };

  // Validate a single event and return errors for that row
  const validateEvent = (event, index) => {
    const errors = [];

    // Check required fields
    requiredFields.forEach((field) => {
      if (!event[field] || event[field].toString().trim() === '') {
        errors.push(`Row ${index + 1}: Missing required field "${field}".`);
      }
    });

    // Validate latitude and longitude
    if (event.latitude && event.longitude) {
      const lat = parseFloat(event.latitude);
      const lng = parseFloat(event.longitude);
      if (isNaN(lat) || lat < -90 || lat > 90) {
        errors.push(`Row ${index + 1}: Invalid latitude (must be between -90 and 90).`);
      }
      if (isNaN(lng) || lng < -180 || lng > 180) {
        errors.push(`Row ${index + 1}: Invalid longitude (must be between -180 and 180).`);
      }
    }

    // Validate dates with stricter format checking (YYYY-MM-DD)
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (event.event_date) {
      if (!dateFormatRegex.test(event.event_date) || isNaN(Date.parse(event.event_date))) {
        errors.push(`Row ${index + 1}: Invalid event_date format. Must be YYYY-MM-DD (e.g., 2025-04-01).`);
      }
    }
    if (event.event_end_date) {
      if (!dateFormatRegex.test(event.event_end_date) || isNaN(Date.parse(event.event_end_date))) {
        errors.push(`Row ${index + 1}: Invalid event_end_date format. Must be YYYY-MM-DD (e.g., 2025-04-01).`);
      }
    }

    // Validate language
    if (event.language && !['TH', 'EN', 'CN'].includes(event.language.toUpperCase())) {
      errors.push(`Row ${index + 1}: Invalid language. Must be "TH", "EN", or "CN".`);
    }

    // Validate type against valid options
    const validTypes = [
      'Product & Service Fair',
      'Promotions',
      'Arts & Films',
      'Live Performances',
      'Sports & Competitions',
      'Travel & Photos',
      'Business & Investment',
      'Education & Family',
      'Other',
    ];
    if (event.type && !validTypes.includes(event.type)) {
      errors.push(`Row ${index + 1}: Invalid type. Must be one of: ${validTypes.join(', ')}.`);
    }

    // Validate status if provided
    if (event.status && !['pending', 'approved', 'rejected'].includes(event.status.toLowerCase())) {
      errors.push(`Row ${index + 1}: Invalid status. Must be "pending", "approved", or "rejected".`);
    }

    return errors;
  };

  // Validate all events and collect errors
  const validateEvents = (events) => {
    let allErrors = [];
    events.forEach((event, index) => {
      const errors = validateEvent(event, index);
      allErrors = [...allErrors, ...errors];
    });
    return allErrors;
  };

  // Handle final batch upload to backend
  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select a file to upload.');
      return;
    }

    if (previewErrors.length > 0) {
      setErrorMessage('Fix the validation errors before uploading.');
      return;
    }

    setUploading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const formattedEvents = previewData.map((event) => ({
        eventName: event.event_name,
        eventDate: event.event_date,
        eventEndDate: event.event_end_date || null,
        eventLocation: event.event_location,
        eventDescription: event.event_description || '',
        eventImageLink: event.event_image_link || '',
        latitude: parseFloat(event.latitude),
        longitude: parseFloat(event.longitude),
        websiteLink: event.website_link || '',
        eventSummary: event.event_summary || '',
        language: event.language.toUpperCase(),
        type: event.type,
        userName: event.user_name || 'System',
        status: event.status ? event.status.toLowerCase() : 'pending',
      }));

      const apiUrl = process.env.REACT_APP_API_URL;

      const promises = formattedEvents.map(async (event, index) => {
        try {
          const response = await axios.post(`${apiUrl}/events`, event);
          return response.data;
        } catch (error) {
          throw new Error(
            `Failed to save event "${event.eventName}" (Row ${index + 1}): ${
              error.response?.data || error.message
            }`
          );
        }
      });

      await Promise.all(promises);
      setSuccessMessage(`Successfully uploaded ${formattedEvents.length} events!`);
      setFile(null);
      setPreviewData([]); // Clear preview data after successful upload
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Container
      component={Paper}
      style={{
        padding: 24,
        maxWidth: 800,
        marginTop: 24,
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Upload Events (Batch)
      </Typography>
      <Typography variant="body1" gutterBottom>
        Upload a CSV or Excel file containing a list of events. The file must include the following columns:{' '}
        {requiredFields.join(', ')}. Optional columns: {optionalFields.join(', ')}.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Example date format: YYYY-MM-DD (e.g., 2025-04-01). Language must be "TH", "EN", or "CN". Type must be one of: Product & Service Fair, Promotions, Arts & Films, Live Performances, Sports & Competitions, Travel & Photos, Business & Investment, Education & Family, Other.
      </Typography>

      <Box my={2}>
        <input
          type="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleFileChange}
          style={{ display: 'block', marginBottom: 16 }}
        />
        {previewData.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom>
              Preview Data
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {Object.keys(previewData[0]).map((key) => (
                      <TableCell key={key} sx={{ fontWeight: 'bold' }}>
                        {key}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Object.values(row).map((value, cellIndex) => (
                        <TableCell key={cellIndex}>{value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {previewErrors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
            {previewErrors.join('\n')}
          </Alert>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={uploading || !file || previewErrors.length > 0}
          startIcon={uploading ? <CircularProgress size={20} /> : null}
          sx={{ mt: 2 }}
        >
          {uploading ? 'Uploading...' : 'Upload Events'}
        </Button>
      </Box>

      {successMessage && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
};

export default EventUpload;
