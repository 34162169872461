import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../components/Header/Header';
import List from '../components/List/List';
import Map from '../components/Map/Map';
import { Box } from '@mui/material';

const Home = () => {
  const [places, setPlaces] = useState([]);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [childClicked, setChildClicked] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [coordinates, setCoordinates] = useState({ lat: 13.7649161, lng: 100.5357104 });
  const [bounds, setBounds] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Filters in the header
  const [type, setType] = useState('All');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 7))
  );
  const [language, setLanguage] = useState('TH');

  // Update events based on search from the search button or autocomplete selection.
  const updatePlaces = async (hits) => {
    setIsLoading(true);
    try {
      setPlaces(hits.filter((hit) => hit.event_name));
      setFilteredPlaces([]);
    } catch (error) {
      console.error('Error updating places:', error);
    }
    setIsLoading(false);
  };

  // On mount, get user location.
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoordinates({ lat: latitude, lng: longitude });
      },
      () => {
        console.warn('Geolocation permission denied or unavailable.');
      }
    );
  }, []);

  // Toggle list panel.
  const [showList, setShowList] = useState(false);
  const toggleList = () => {
    setShowList(!showList);
  };

  // When a user clicks a photo in the list.
  const onLocationClick = (place) => {
    setCoordinates({ lat: place.latitude, lng: place.longitude });
    setSelectedEventId(place.id);
    const index = places.findIndex((p) => p.id === place.id);
    setChildClicked(index);
  };

  // When a marker on the map is clicked.
  const onMarkerClick = (id) => {
    const list = filteredPlaces.length ? filteredPlaces : places;
    const index = list.findIndex((p) => p.id === id);
    if (index !== -1) {
      setChildClicked(index);
      setSelectedEventId(id);
      const selectedPlace = list[index];
      setCoordinates({ lat: selectedPlace.latitude, lng: selectedPlace.longitude });
    }
  };

  return (
    <div>
      <CssBaseline />
      <Header
        setCoordinates={setCoordinates}
        type={type}
        setType={setType}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        language={language}
        setLanguage={setLanguage}
        mapBounds={bounds}
        updatePlaces={updatePlaces}
      />
      <Box sx={{ position: 'relative', height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
        <Map
          setCoordinates={setCoordinates}
          setBounds={setBounds}
          coordinates={coordinates}
          places={filteredPlaces.length ? filteredPlaces : places}
          selectedEventId={selectedEventId}
          onMarkerClick={onMarkerClick}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '64px',
            right: '0',
            width: showList ? { xs: '250px', md: '300px' } : '0',
            height: 'calc(100vh - 64px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            overflowY: 'auto',
            transition: 'width 0.3s',
            zIndex: 10,
          }}
        >
          {showList && (
            <List
              places={filteredPlaces.length ? filteredPlaces : places}
              childClicked={childClicked}
              isLoading={isLoading}
              onLocationClick={onLocationClick}
            />
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '10px', md: '75px' },
            right: { xs: '5px', md: '10px' },
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            borderRadius: '4px',
            cursor: 'pointer',
            zIndex: 11,
            padding: '5px',
          }}
          onClick={toggleList}
        >
          {showList ? 'Hide' : 'Show'} List
        </Box>
      </Box>
    </div>
  );
};

export default Home;